import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import Section from '../components/Section';
import MarkdownPage from '../templates/MarkdownPage';
import NewsletterSignupForm from '../components/NewsletterSignupForm';
import BlogRoll from '../components/BlogRoll';

const CareersPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.careersPage;
  const { edges: jobPosts } = data.jobPosts;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <MarkdownPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          content={page[pageContext.locale].body || page[defaultLocale].body}
        />
        <Section>
          <div className="container">
            <BlogRoll blogPosts={jobPosts} isVerticalLayout />
          </div>
        </Section>
        <Section colorScheme="white">
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

CareersPageTemplate.propTypes = {
  data: PropTypes.shape({
    careersPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CareersPageTemplate;

export const pageQuery = graphql`
  query CareersPage($id: String!, $locale: String!) {
    careersPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
          title
          hero
          body
        }
        de {
          title
          hero
          body
        }
      }
    }
    jobPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { collection: { eq: "job-posts" }, locale: { eq: $locale } }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
